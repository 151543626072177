import React from "react";
import { Route, Routes } from "react-router-dom";

// Constant Imports

// Lazy Protected Component Wrapper - To Wrap all Lazy Loaded Components with Authentication Wrapper

// Authentication Service

// 2. Page Not Found
import PageNotFound from "pages/error/404";
import LandingPage2 from "pages/landingPage2";
// import ParkingLot from "pages/user-portal/user/ParkingLot";

import { AuthProvider } from "services/auth";

const ActivitiesV2 = React.lazy(() => import('pages/adminV2/Activities'));
const UsersV2 = React.lazy(() => import('pages/adminV2/Users'));
const SurveyV2 = React.lazy(() => import('pages/adminV2/Survey'));
const VSetCategoriesV2 = React.lazy(() =>import('pages/adminV2/VSetCategories'));
const VSetV2 = React.lazy(() =>import('pages/adminV2/VSets'));
const ClustersV2 = React.lazy(() =>import('pages/adminV2/Clusters'));

const UserDashboard = React.lazy(() => import("pages/user-portal/user/UserDashboard"));
const NewUserDashboard = React.lazy(() => import("pages/user-portal/user/NewUserDashboard"));

// 3. Dashboard
const AdminDashboard = React.lazy(() => import("pages/admin/dashboard"));
const AdminV2Dashboard = React.lazy(() => import("pages/adminV2"));

// 3.1 VSorts Cluster
const VSortsCluster = React.lazy(() => import("pages/admin/dashboard/VSortsCluster"));
const VSortsClusterCreation = React.lazy(() => import("pages/admin/dashboard/VSortsCluster/Form"));

// 3.2 VSorts Containers
const VSortsContainer = React.lazy(() => import("pages/admin/dashboard/VSortsContainer"));

// 3.3 Survey (Pre-Survey, Post-Survey, Final Feedback)
const Survey = React.lazy(() => import("pages/admin/dashboard/Survey"));
const SurveyForm = React.lazy(() => import("pages/admin/dashboard/Survey/Form"));

// 3.4 Categories
const VSetsCategory = React.lazy(() => import("pages/admin/dashboard/VSetCategories"));
const Users = React.lazy(() => import("pages/admin/dashboard/Users"));

// View scores
const ViewScores = React.lazy(() => import("pages/user-portal/scores/index"));

const VSetsEvaluation = React.lazy(() => import("pages/user-portal/vSets/VSetsEvaluation"));

// `ApplicationRoutes` is the function that handles all the routes present in the application
export default function ApplicationRoutes() {
    return (
        <AuthProvider>
            <Routes>
                {/* Default route re-directing to PageNotFound */}
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/" element={<LandingPage2 />} />
                {/* <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/sign-up" element={<SignUpPage />} /> */}
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                {/* <Route path="/dashboard-redirect" element={<DashboardRedirectPage />} />

                <Route path="/" element={<LazyAuthProtectedComponentWrappper Component={DashboardLayout} />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/unpacking" element={<ParkingLot />} />
                </Route> */}

                {/* Version 2 Admin panel vset routes  */}
                {/* <Route path='/v2/admin/vsets/create' element={<LazyAuthProtectedComponentWrappper Component={CreateVsetLayout} />}>
                    <Route
                        path=""
                        element={<LazyProtectedAdminComponentWrapper Component={CreateVset} />}
                    />

                    <Route
                        path="parameters"
                        element={<LazyProtectedAdminComponentWrapper Component={CreateVsetParameters} />}
                    />

                    <Route
                        path="unpacking"
                        element={<LazyProtectedAdminComponentWrapper Component={CreateVsetUnpacking} />}
                    />

                    <Route
                        path="assign"
                        element={<LazyProtectedAdminComponentWrapper Component={CreateVsetAssign} />}
                    />


                </Route> */}


                {/* Version 2 Admin panel routes  */}
                {/* <Route path='/v2/admin' element={<LazyAuthProtectedComponentWrappper Component={AdminV2Dashboard} />}>
                    <Route
                        path="vsets"
                        element={<LazyProtectedAdminComponentWrapper Component={VSetV2} />}
                    />
                    <Route
                        path="clusters"
                        element={<LazyProtectedAdminComponentWrapper Component={ClustersV2} />}
                    />
                    <Route
                        path="activities"
                        element={<LazyProtectedAdminComponentWrapper Component={ActivitiesV2} />}
                    />
                    <Route
                        path="users"
                        element={<LazyProtectedAdminComponentWrapper Component={UsersV2} />}
                    />
                    <Route
                        path="vsetcategories"
                        element={<LazyProtectedAdminComponentWrapper Component={VSetCategoriesV2} />}
                    />
                    <Route
                        path="forms"
                        element={<LazyProtectedAdminComponentWrapper Component={SurveyV2} />}
                    />
                </Route>

                <Route path="/admin" element={<LazyProtectedAdminComponentWrapper Component={AdminDashboard} />}>
                    <Route
                        path="vset-categories"
                        element={<LazyProtectedAdminComponentWrapper Component={VSetsCategory} />}
                    />

                    <Route path="users" element={<LazyProtectedAdminComponentWrapper Component={Users} />} /> */}
                    {/* VSort Cluster Routes - START */}
                    {/* <Route
                        path="vsort-cluster"
                        element={<LazyProtectedAdminComponentWrapper Component={VSortsCluster} />}
                    />
                    <Route
                        path="vsort-cluster/view/:clusterID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => <VSortsClusterCreation isView={true} />}
                            />
                        }
                    />
                    <Route
                        path="vsort-cluster/create/:clusterID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => <VSortsClusterCreation isCreate={true} />}
                            />
                        }
                    />
                    <Route
                        path="vsort-cluster/edit/:clusterID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => <VSortsClusterCreation isEdit={true} />}
                            />
                        }
                    /> */}
                    {/* VSort Cluster Routes - END */}

                    {/* VSort Container Routes - START */}
                    {/* <Route path="vSets" element={<LazyProtectedAdminComponentWrapper Component={VSortsContainer} />} />
                    <Route
                        path="vSets/view/:containerID"
                        element={<LazyProtectedAdminComponentWrapper Component={() => <VSetTabView isView={true} />} />}
                    />
                    <Route
                        path="vSets/create/:containerID"
                        element={
                            <LazyProtectedAdminComponentWrapper Component={() => <VSetTabView isCreate={true} />} />
                        }
                    />
                    <Route
                        path="vSets/edit/:containerID"
                        element={<LazyProtectedAdminComponentWrapper Component={() => <VSetTabView isEdit={true} />} />}
                    /> */}
                    {/* VSort Container Routes - END */}

                    {/* NOTE :-  Added key since we are using the same component to render pre-survey, post-survey, lagniappe-page and Unpacking Questions */}
                    {/* Pre-Survey Routes - START */}
                    {/* <Route
                        path="pre-survey"
                        key={window.location.href}
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => <Survey surveyType={FormTypes.presurvey} surveyLabel={"Pre Survey"} />}
                            />
                        }
                    />
                    <Route
                        path="pre-survey/view/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => <SurveyForm surveyQuestionsType={FormTypes.presurvey} isView={true} />}
                            />
                        }
                    />
                    <Route
                        path="pre-survey/create/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <SurveyForm surveyQuestionsType={FormTypes.presurvey} isCreate={true} />
                                )}
                            />
                        }
                    />
                    <Route
                        path="pre-survey/edit/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => <SurveyForm surveyQuestionsType={FormTypes.presurvey} isEdit={true} />}
                            />
                        }
                    /> */}
                    {/* Pre-Survey Routes - END */}

                    {/* Post-Survey Routes - START */}
                    {/* <Route
                        path="post-survey"
                        key={window.location.href}
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <Survey surveyType={FormTypes.postsurvey} surveyLabel={"Post Survey"} />
                                )}
                            />
                        }
                    />
                    <Route
                        path="post-survey/view/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <SurveyForm surveyQuestionsType={FormTypes.postsurvey} isView={true} />
                                )}
                            />
                        }
                    />
                    <Route
                        path="post-survey/create/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <SurveyForm surveyQuestionsType={FormTypes.postsurvey} isCreate={true} />
                                )}
                            />
                        }
                    />
                    <Route
                        path="post-survey/edit/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <SurveyForm surveyQuestionsType={FormTypes.postsurvey} isEdit={true} />
                                )}
                            />
                        }
                    /> */}
                    {/* Post-Survey Routes - END */}

                    {/* Final-Feedback Routes - START */}
                    {/* <Route
                        path="lagniappe-page"
                        key={window.location.href}
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <Survey surveyType={FormTypes.finalfeedback} surveyLabel={"Lagniappes"} />
                                )}
                            />
                        }
                    />
                    <Route
                        path="lagniappe-page/view/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <SurveyForm surveyQuestionsType={FormTypes.finalfeedback} isView={true} />
                                )}
                            />
                        }
                    />
                    <Route
                        path="lagniappe-page/create/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <SurveyForm surveyQuestionsType={FormTypes.finalfeedback} isCreate={true} />
                                )}
                            />
                        }
                    />
                    <Route
                        path="lagniappe-page/edit/:surveyID"
                        element={
                            <LazyProtectedAdminComponentWrapper
                                Component={() => (
                                    <SurveyForm surveyQuestionsType={FormTypes.finalfeedback} isEdit={true} />
                                )}
                            />
                        }
                    /> */}
                    {/* Final-Feedback Routes - END */}

                    {/* Default Dashboard Route  */}
                    {/* //TODO:- TO BE REPLACED WITH ANALYTICS OR QUICK-GLANCE INFORAMTION */}
                    {/* <Route index element={<PageNotFound />} />
                </Route> */}

                {/* Vsets Matching */}
                {/* <Route
                    path="/user/vsort-cluster/:clusterID"
                    element={<LazyProtectedUserComponentWrapper Component={VSortsClusterDetail} />}
                />
                <Route
                    path="/user/vsort-cluster/eval"
                    element={<LazyProtectedUserComponentWrapper Component={VSetsEvaluation} />}
                />

                <Route path="/user" element={<LazyProtectedUserComponentWrapper Component={UserDashboardLayout} />}>
                    <Route path="old-dashboard" element={<UserDashboard />} />
                    <Route path="dashboard" element={<NewUserDashboard />} />
                    <Route path="parking-lot" element={<ParkingLot />} />
                    <Route path="unpacking" element={<Unpacking />} />
                    <Route path="clusters" element={<VSortsCluster />} /> */}
                    {/* <Route path="vsort-cluster/:clusterID" element={<VSortsClusterDetail />} /> */}
                    {/* <Route path="vset-sorting" element={<VSetSorting />} />
                    <Route path="view-vset-reponses/:clusterID" element={<VSetResponses />} />
                    <Route path="view-flagged-reponses/:clusterID" element={<FlaggedVignettes />} />
                    <Route path="view-vignette-scores/:clusterID" element={<ViewScores />} />
                    <Route index element={<PageNotFound />} />
                </Route> */}

                {/* Default Route for invalid routes */}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </AuthProvider>
    );
}
