// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Constants
import { StringConstants } from "constants/string.constants";

// Custom Styles
import { ErrorPageInformation, ErrorPageInformationWrapper, ErrorPageWrapper } from "pages/error/Error.styles";

// `PageNotFound` is the function to show the user the UI when the user encounters a URL or a path which does not exist
export default function PageNotFound() {
    return (
        <ErrorPageWrapper>
            <ErrorPageInformationWrapper>
                <ErrorPageInformation>
                    <h1>{StringConstants.pageNotFoundErrorCode}</h1>
                    <h2>{StringConstants.pageNotFoundErrorTitle}</h2>
                    <p>{StringConstants.pageNotFoundDescription}</p>
                </ErrorPageInformation>
                <a className="action-button" href="/">
                    {StringConstants.goToHomePage}
                </a>
            </ErrorPageInformationWrapper>
        </ErrorPageWrapper>
    );
}
