import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import './HeroSection.css'

const textRevelas = ["Enjoyable", "Less Tiring", "Simple", "75% Faster"]

const HeroSection:React.FC = () => {
  const [textIndex, setTextIndex] = useState<number>(0);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTextIndex(textIndex => textIndex + 1)
      if (textIndex == textRevelas.length - 1) setTextIndex(0);
    }, 3000);
    return () => clearInterval(intervalID);
  }, [textIndex])

  return (
    <div className='herosection'>
      <div className='herosectionContainer'>
        <motion.p 
          initial= {{ opacity: 0 }}
          animate={{opacity: 1}}
          transition={{
            delay: 1, 
            type: 'spring', 
            mass: 0.4
          }}
        >
          Making the Qualitative Data Analysis
        </motion.p>
        <motion.p
          initial= {{ opacity: 0 }}
          animate={{opacity: 1}}
          transition={{delay: 1.5}}
        >
          Workflow &nbsp;
          <motion.span
            key={textIndex}
            initial= {{ opacity: 0 }}
            animate={{opacity: 1}}
            transition={{delay: 0.1, ease: "easeInOut"}}
          >
            {textRevelas[textIndex]}
          </motion.span>
        </motion.p>
      </div>
    </div>
  )
}

export default HeroSection