import styled from "styled-components";

export const ErrorPageWrapper = styled.div`
    position: relative;
    height: 100vh;
`;

export const ErrorPageInformationWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`;

export const ErrorPageInformation = styled.div`
    position: relative;
    margin-bottom: 12px;
    h1 {
        font-family: titillium web, sans-serif;
        font-size: 186px;
        font-weight: 900;
        margin: 0;
        line-height: 150px;
        text-transform: uppercase;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: black;
        background-size: cover;
        background-position: center;
        margin-bottom: 16px;
    }
    h2 {
        font-family: titillium web, sans-serif;
        font-size: 26px;
        font-weight: 700;
        margin: 0;
    }
    p {
        font-family: montserrat, sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        text-transform: uppercase;
    }
`;
