import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// All Application Routes
import Routes from "routes";

// React Nestable style
import "react-nestable/dist/styles/index.css";

import { COLOR } from "constants/color.constants";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const queryClient = new QueryClient();

const theme = createTheme({
    palette: {
        background: {
            default: COLOR.background,
        },
    },
    spacing: (x: number) => `${x}px`,
    shape: {
        borderRadius: 1,
    },
    typography: {
        allVariants: {
          fontFamily: 'Montserrat',
          textTransform: 'none',
          fontSize: 16,
        },
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 378,
          md: 769,
          lg: 900,
          xl: 1200,
        },
    },
});

const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <Routes />
                {/* React Query Dev Tools - To view the queries and mutations made.  */}
                {/* NOTE :- Disabled by default in production mode */}
                <ReactQueryDevtools initialIsOpen={false} position="top-right" />
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
