import { IconButton } from "@mui/material";
import ArrowLeft from "assets/icons/arrowleft.svg";
import ArrowRight from "assets/icons/arrowright.svg";
import FeatureImg3 from "assets/images/Dashboard.png";
import FeatureImg1 from "assets/images/VSets - CoPilot.png";
import FeatureImg4 from "assets/images/VSets - Sorting-1.png";
import FeatureImg2 from "assets/images/VSets - Survey Select-1.png";
import React, { useRef } from "react";
import Slider from "react-slick";
import "./FeatureSlides.css";

const FeaturesSlides: React.FC = () => {
    const sliderRef = useRef<Slider>(null);

    const features = [
        {
            image: FeatureImg1,
            title: "AI CoPilots Delivering Analysis & Insights",
            description: `VSorts™ AI CoPilots revolutionize qualitative data analysis in the PreK-20 education 
            sector with expert training in Cultural Proficiency© for nuanced data interpretation. 
            Bridging research and practice, they enhance decision-making by delivering rich, 
            culturally proficient qualitative data analysis and insights in seconds. These AI 
            assistants elevate the value of qualitative data, ensuring both accessibility and depth 
            of understanding for all stakeholders.`,
        },
        {
            image: FeatureImg2,
            title: "Surveys",
            description: `Revolutionize survey analysis with VSorts™ AI. Our cutting-edge platform, enhanced by expert AI CoPilots, 
            delivers rapid, on-demand qualitative data analysis. Eliminate unused data and achieve a significant 75% time-saving.
            VSorts™ minimizes cognitive fatigue and provides immediate access to pre-analyzed or real-time insights, simplifying decision-making.
            Discover the ease and depth of our survey solution, particularly for open-ended user responses.`,
        },
        {
            image: FeatureImg3,
            title: "Dashboard",
            description: `The VSorts™ AI Dashboard, featuring the Cluster Deployment User Interface, revolutionizes qualitative data handling. 
            It streamlines data collection, transcription, and real-time analysis with AI CoPilot support, centralizing cluster deployment and curated activities. 
            This tool enhances data collection, analysis, storage, and visualization efficiency, making it indispensable for researchers and 
            consultants seeking streamlined, practical qualitative data analysis solutions.`,
        },
        {
            image: FeatureImg4,
            title: "VSets",
            description: `VSets, integral to VSorts™ AI, are effective in academic research and practical applications for swiftly assessing competencies, 
            awareness, and biases. They enable a deep understanding of individual and collective perspectives, making them invaluable for research 
            and organizational development, offering insights for team coaching and self-improvement strategies in educational and corporate settings.`,
        },
    ];

    const handleClickNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const handleClickPrevious = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const settings = {
        infinite: features.length > 0,
        useTransform: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    };

    return (
        <div className="featureSlideWrapper">
            <Slider ref={sliderRef} {...settings}>
                {features.map((feature) => {
                    return (
                        <div>
                            <div className="featureSlideContainer">
                                <div className="featureSlideImageContainer">
                                    <img src={feature.image} alt="Features" className="featureSlideImage" />
                                </div>
                                <div className="featureSlideContentContainer">
                                    <div>
                                        <div>
                                            <h2 className="featureSlideContentTitle">{feature.title}</h2>
                                        </div>

                                        <div className="featureSlideContainerContent">{feature.description}</div>
                                    </div>

                                    <div>
                                        <IconButton disableRipple onClick={() => handleClickPrevious()}>
                                            <img src={ArrowLeft} alt="Left" />
                                        </IconButton>
                                        <IconButton disableRipple onClick={() => handleClickNext()}>
                                            <img src={ArrowRight} alt="Right" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default FeaturesSlides;
