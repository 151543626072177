import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { SECTIONS } from 'constants/landing-page.constants'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Brand } from '../../../assets/icons/VSORTSAI.svg'
import './Navbar.css'

interface Props {
  onScroll: (refName: string) => void;
}

const Navbar: React.FC<Props> = ({onScroll}) => {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false)
  const [navbarChange, setNavbarChange] = useState(false);

  const changeNavbarBackGround = () => {
    if (window.scrollY >= 80) {
      setNavbarChange(true);
    } else {
      setNavbarChange(false);
    }
  };

  window.addEventListener('scroll', changeNavbarBackGround);

  const handleLogin = () => {
    navigate("/login")
  }
  
  const handleGettingStarted = () => {
    navigate("/coming-soon")
  }

  return (
    <nav className={`navbar2 ${navbarChange ? 'active' : ''} ${showNavbar ? 'show' : ''}`}>
      <div className="navbarContainer">
        <div className='navbarContainertop'>
          <div className="navbarContainerLogo">
            <Brand />
          </div>
          <div className="navbarMenuIcon" onClick={() => setShowNavbar(!showNavbar)}>
            {
              showNavbar ? <CloseIcon /> : <MenuIcon />
            }
          </div>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <a onClick={() => onScroll(SECTIONS.VSORTS)}>What is VSorts?</a>
            </li>
            <li>
              <a onClick={() => onScroll(SECTIONS.FEATURES)}>Features</a>
            </li>
            <li>
              <a onClick={() => onScroll(SECTIONS.ENTERPRISE)}>Enterprise</a>
            </li>
            <li>
              <a onClick={() => onScroll(SECTIONS.CONTACT)}>Contact</a>
            </li>
          </ul>
          {/* <div className={`navbarActionButtons ${showNavbar && 'active'}`}>
            <MotionConfig 
              transition={{
                type: "spring", stiffness: 400, damping: 10
              }}
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{scale: 0.95, rotate: '2.5deg'}}
                className='navbarActionButton navbarActionButtons-login'
                onClick={() => handleLogin()}
              >
                Login
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{scale: 0.95, rotate: '-2.5deg'}}
                className='navbarActionButton navbarActionButtons-getStarted'
                onClick={() => handleGettingStarted()}
              >
                Get Started
              </motion.button>
            </MotionConfig>
          </div> */}
        </div>
      </div>
    </nav>
  )
}

export default Navbar