import { RefObject } from 'react';
import SaasPlatformImg from '../../../assets/images/GrowWithVS.svg';
import './SaasPlatform.css';

interface Props {
  vsortsRef: RefObject<HTMLDivElement>;
}

const SaasPlatform:React.FC<Props> = ({ vsortsRef }) => {
  return (
    <div className='saasPlatform' ref={vsortsRef}>
      <div className='saasPlatformContainer'>
        <div className='saasPlatformContainer-left'>
          <h1>Revolutionizing the Qualitative Data Workflow for Research & Future of Work</h1>
          <p>VSorts™ AI revolutionizes qualitative data workflows for Research, the Future of Work, and Consulting—our platform streamlines processes by integrating data collection, speech-to-text transcriptions, analysis, visualization, and storage. Leveraging proprietary AI CoPilots, protected by copyright and trained in diverse frameworks, enables real-time insights to overcome workflow challenges, saving users up to 75% of their time per project.</p>
        </div>
        <img src={SaasPlatformImg} alt='Saas platform' />
      </div>
    </div>
  )
}

export default SaasPlatform