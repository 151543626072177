import React, { RefObject } from "react";
import AnalyticsImage from "../../../assets/images/VSorts Graphic 2.svg";
import EducationResearchImage from "../../../assets/images/VSorts Graphic 3.svg";
import EducatorDevelopmentImage from "../../../assets/images/VSorts Graphic 4.svg";
import OrganizationalDevelopmentImage from "../../../assets/images/VSorts Graphic 5.svg";
import "./Enterprise.css";

const enterpriseData = [
    {
        title: ["Advanced Analytics for", <br />, "PreK-20 Education Research", <br />, "and People Management"],
        content:
            "VSorts™ AI is revolutionizing the PreK-20 education sector with its specialized SaaS platform, streamlining qualitative data analysis from collection to storage. It empowers educational experts to share findings quickly, make data-informed decisions, and provide culturally proficient insights. As a comprehensive, all-in-one solution, VSorts™ AI simplifies and integrates each analysis step, becoming the single source of truth in educational quantitative data analysis workflow. Embrace a new standard in educational qualitative data analysis with VSorts™ AI, underpinned by the Cultural Proficiency© framework. Here, efficiency, insight, and precision unite to revolutionize the PreK-20 sector.",
        image: AnalyticsImage,
    },
    {
        title: ["Education", <br />, "Research"],
        content:
            "VSorts™ AI is revolutionizing education research by providing an all-in-one solution for qualitative data collection, transcription, analysis, reporting, and storage. It integrates expert AI CoPilots, trained in Cultural Proficiency©, to conduct inductive and deductive qualitative data analysis. This synergy between academic theory and practical application empowers researchers to uncover contextually rich insights, propelling innovative research and educational policy.",
        image: EducationResearchImage,
    },
    {
        title: ["Educator", <br />, "Development"],
        content:
            "VSorts™ AI CoPilots transform educator development by enhancing teaching methodologies and classroom strategies. Through user-friendly interfaces like VSets and AI Queries, they apply Cultural Proficiency© insights, offering educators immediate feedback to enable efficient, real-time refinement of teaching methods. AI-assisted analysis promotes inclusive and adaptive teaching, significantly contributing to professional development across the PreK to Gray educational spectrum.",
        image: EducatorDevelopmentImage,
    },
    {
        title: ["Organizational", <br />, "Development and Evaluation"],
        content:
            "VSorts™ AI CoPilots are revolutionizing organizational development and evaluation in education and corporate sectors through culturally proficient, context-specific qualitative data analysis. They assist institutions in enhancing processes, policies, and collegiality by addressing sociocultural gaps among stakeholders. Cultural Proficiency© AI CoPilots are invaluable for leaders cultivating culturally conscious and inclusive environments aligned with their organizational mission, vision, and values.",
        image: OrganizationalDevelopmentImage,
    },
];
interface Props {
    enterpriseRef: RefObject<HTMLDivElement>;
}

const Enterprise: React.FC<Props> = ({ enterpriseRef }) => {
    return (
        <div className="enterprise" id='enterprise' ref={enterpriseRef}>
            <div className="enterpriseContainer">
                {enterpriseData.map((enterprise, index) => (
                    <div className={`enterpriseContainer__card ${index % 2 == 1 ? "reverse" : ""}`}>
                        <div className="enterpriseContainer__cardContent">
                            <h1>{enterprise.title}</h1>
                            <p>{enterprise.content}</p>
                        </div>
                        <div className="enterpriseContainer__cardImage">
                            <img src={enterprise.image} alt="" />
                        </div>
                    </div>
                ))}
                <div className="enterpriseContainer__sector">
                    <h1>Don’t see your business sector? Lets change that.</h1>
                    <button className="navbarActionButton navbarActionButtons-getStarted">Schedule a meeting</button>
                </div>
            </div>
        </div>
    );
};

export default Enterprise;
